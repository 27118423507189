const translationFR = {
  metaDescription : "Pas de Salon de l’Auto. Mais partout dans les rues.",

  // HEADER 
  mainURL: "https://www.volvocars.com/",
  BMSMessage1 : "Cette année, le Salon de l’Auto n’aura pas&nbsp;lieu. <br /> Mais heureusement, nous sommes partout&nbsp;ailleurs. <br /> <br />Joliment en mouvement ou garée le long d’un&nbsp;trottoir, votre future Volvo n’est surement pas très&nbsp;loin.",
  BMSMessage2 : "Nous avons envoyé 3 street photographers dans nos rues pour capturer tous les modèles&nbsp;Volvo.<br />Découvrez-les&nbsp;ici.",
  meetPhotographers : "Rencontrez nos street photographers",
  scrollDown : "Scrollez pour découvrir nos modèles et nos offres temporaires.",
  btnHeader : "Découvrez notre Street Configurator",
  linkXC40 : "https://www.volvocars.com/fr-be/v/cars/xc40",
  linkXC60 : "https://www.volvocars.com/fr-be/v/cars/xc60",
  linkXC90 : "https://www.volvocars.com/fr-be/v/cars/xc90",
  linkV60 : "https://www.volvocars.com/fr-be/v/cars/v40",

  // MENU
  ourCars: "Nos voitures",
  ourCarsLink: "https://www.volvocars.com/fr-be/modeles",
  item1 : "Configurateur",
  linkItem1 : "https://www.volvocars.com/fr-be/build",
  item2 : "Conduire une Volvo électrique",
  linkItem2 : "https://www.volvocars.com/fr-be/v/cars/electric-cars",
  item3 : "Trouver un distributeur",
  linkItem3 : "https://www.volvocars.com/fr-be/dealers/distributeurs",
  item4 : "Contact",
  linkItem4 : "https://www.volvocars.com/fr-be/contact",
  switchLanguage: "Belgium (Nederlands)", 

  // MAIN
  footerMain: "Vous avez repéré une Volvo&nbsp;? Testez notre Street Configurator",
  

  //CASES
  ctaAllCaseTop : "Demandez votre offre",
  ctaAllCaseTopLink : "https://www.volvocars.com/fr-be/quote?campaigncode=Brus-motorshow-2022-B_VCBE",
  //CASE1 - XC40
  case1Photographer : "© Thomas Switn Sweertvaegher, Ottergemsesteenweg – 9000 Gent",
  case1Copy1 : "Plug-in Hybrid R-Design",
  case1Copy2 : "Ice White <br />18” wheels",
  case1Copy3 : "Modèle de base àpd <span>32.800&nbsp;€</span>",
  case1CtaImage : "Découvrez la&nbsp;XC40",
  case1LinkCtaImage : "https://www.volvocars.com/fr-be/v/cars/xc40",
  case1Cta : "Découvrez nos <br /> offres",
  case1Link : "https://www.volvocars.com/fr-be/l/offres/b2c/xc40 ",
  //CASE2 - XC60
  case2Photographer : "© Wouter Van Vaerenbergh, Kwatrechtstraat – 9230 Wetteren",
  case2Copy1 : "Plug-in Hybrid R-Design",
  case2Copy2 : "Silver Dawn <br />19” wheels",
  case2Copy3 : "Modèle de base àpd <span>51.200&nbsp;€</span>",
  case2CtaImage : "Découvrez la&nbsp;XC60",
  case2LinkCtaImage : "https://www.volvocars.com/fr-be/v/cars/xc60",
  case2Cta : "Découvrez nos <br /> offres",
  case2Link : "https://www.volvocars.com/fr-be/l/offres/b2c/xc60",
  //CASE3 - XC90
  case3Photographer : "© Thomas Switn Sweertvaegher, Nieuwevaart - 9000 Gent",
  case3Copy1 : "Mild Hybrid Inscription",
  case3Copy2 : "Birch Light <br />21” wheels",
  case3Copy3 : "Modèle de base àpd <span>73.840&nbsp;€</span>",
  case3CtaImage : "Découvrez la&nbsp;XC90",
  case3LinkCtaImage : "https://www.volvocars.com/fr-be/v/cars/xc90",
  case3Cta : "Découvrez nos <br /> offres",
  case3Link : "https://www.volvocars.com/fr-be/l/offres/b2c/xc90",
  //CASE4 - V60
  case4Photographer : "© Thomas Switn Sweertvaegher, Rodebergstraat - 8950 Heuvelland",
  case4Copy1 : "Mild Hybrid Momentum Pro",
  case4Copy2 : "Pebble Grey <br />19” wheels",
  case4Copy3 : "Modèle de base àpd <span>40.550&nbsp;€</span>",
  case4CtaImage : "Découvrez la&nbsp;V60",
  case4LinkCtaImage : "https://www.volvocars.com/fr-be/v/cars/v60",
  case4Cta : "Découvrez nos <br /> offres",
  case4Link : "https://www.volvocars.com/fr-be/l/offres/b2c/v60",
  //VIDEO
  videoCtaImage : "Play&nbsp;full <br /> Video",
  videoCtaImageStop : "Pause <br /> Video",
  ctaVideo : "Découvrez nos street photographers",
  seeMore : "Découvrez-plus",
  videoURL : "https://player.vimeo.com/video/654445872?h=0209810e13",

  //FOOTER 
  footer1: "Découvrez toutes nos offres",
  footer1Link: "https://www.volvocars.com/fr-be/acheter/votre-nouvelle-volvo/offres",
  footer2: "Trouvez votre distributeur",
  footer2Link: "https://www.volvocars.com/fr-be/dealers/distributeurs ",
  footer3: "Testez notre Street Configurator",

  linkStreetConfigurator: "https://volvostreetconfigurator.be/fr",

}

export default translationFR;