import React, { Component } from 'react'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Case from "./components/main/Case";
import isMobile from './constants/global';
import i18n from "i18next";


import { t } from 'i18next';





gsap.registerPlugin(ScrollTrigger);


export class Main extends Component{
 
  render (){ 
    // console.log(i18n.language);
    if (i18n.language === "lu") {
      var casesLang = [
        {backgroundColor:"#D7A59C", copyrightPhoto: t('case1Photographer'), carModele:"XC40", ctaImage:t('case1CtaImage'), copy1:t('case1Copy1'), copy2:t('case1Copy2'), copy3:t('case1Copy3'), cta:t('case1Cta'), link:t('case1Link'), linkCta:t('case1LinkCtaImage')},
        {backgroundColor:"#D9B48B", copyrightPhoto: t('case2Photographer'), carModele:"XC60", ctaImage:t('case2CtaImage'), copy1:t('case2Copy1'), copy2:t('case2Copy2'), copy3:t('case2Copy3'), cta:t('case2Cta'), link:t('case2Link'), linkCta:t('case2LinkCtaImage')},
        {backgroundColor:"#E1DFDD", copyrightPhoto: t('case4Photographer'), carModele:"V60", ctaImage:t('case4CtaImage'), copy1:t('case4Copy1'), copy2:t('case4Copy2'), copy3:t('case4Copy3'), cta:t('case4Cta'), link:t('case4Link'), linkCta:t('case4LinkCtaImage')},
        ];;
    }
    else{
      var casesLang = [
        {backgroundColor:"#D7A59C", copyrightPhoto: t('case1Photographer'), carModele:"XC40", ctaImage:t('case1CtaImage'), copy1:t('case1Copy1'), copy2:t('case1Copy2'), copy3:t('case1Copy3'), cta:t('case1Cta'), link:t('case1Link'), linkCta:t('case1LinkCtaImage')},
        {backgroundColor:"#E1DFDD", copyrightPhoto: t('case2Photographer'), carModele:"XC60", ctaImage:t('case2CtaImage'), copy1:t('case2Copy1'), copy2:t('case2Copy2'), copy3:t('case2Copy3'), cta:t('case2Cta'), link:t('case2Link'), linkCta:t('case2LinkCtaImage')},
        {backgroundColor:"#D9B48B", copyrightPhoto: t('case3Photographer'), carModele:"XC90", ctaImage:t('case3CtaImage'), copy1:t('case3Copy1'), copy2:t('case3Copy2'), copy3:t('case3Copy3'), cta:t('case3Cta'), link:t('case3Link'), linkCta:t('case3LinkCtaImage')},
        {backgroundColor:"#E1DFDD", copyrightPhoto: t('case4Photographer'), carModele:"V60", ctaImage:t('case4CtaImage'), copy1:t('case4Copy1'), copy2:t('case4Copy2'), copy3:t('case4Copy3'), cta:t('case4Cta'), link:t('case4Link'), linkCta:t('case4LinkCtaImage')},
        {backgroundColor:"#E1DFDD", copyrightPhoto: t('case4Photographer'), carModele:"Video", ctaImage:t('videoCtaImage'), copy1:t('case4Copy1'), copy2:t('case4Copy2'), copy3:t('case4Copy3'), cta:t('case4Cta'), link:t('case4Link'), linkCta:t('case4LinkCtaImage'), isVideo:true},
        ];;
    }
    
    return <main className={"transitionHeader " + i18n.language + (isMobile.isMobile ? " mobile" : "")}>

        <Case cases={ casesLang } />
    </main>}
    
};
export default Main;
