import React, { Component } from 'react'



import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger, } from "gsap/ScrollTrigger";

import {Countdown} from "./components/header/Countdown";
import CarsHeader from "./components/header/CarsHeader";
import VisuAnimation from "./components/header/VisuAnimation";
import isMobile from './constants/global'
import i18n from "i18next";
import { t } from 'i18next';


// const isMobile = true;

// import { isMobile } from './constants/global'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export class Header extends Component{
  // constructor(props) {
  // super(props);
  // this.handleScroll = this.handleScroll.bind(this)
  // }

  state = {
    isLoaded: false,
    showVideo: false,
    onImage : false,
    wasBlack : false,
    timeOut: null,
    currentLayer : 0,
    scrollTween : null,
    oldScrollValue : 0,
   }
  handleCallback = (childData) =>{
    this.setState({isLoaded: true})
  }
  handleVideoCallBack = (childData) =>{
    this.setState({showVideo: !this.state.showVideo})
  }
  
  // scrollToTop(onTop){
  //   if(window.scrollY < window.innerHeight && !onTop){
  //     gsap.to(window, .6, { 
  //       scrollTo: 0,
  //       duration : 1.5,
  //       onComplete : function(){
  //         window.addEventListener('scroll', this.scrollToTop())
  //       }
  //     });
  //   }
  // }

  moveWall(e){
    if(this.state.isLoaded){
      this.setState({onImage: true})

      let visu = e.currentTarget;
      let translationX = visu.dataset.cotransx ; 
      let translationY = visu.dataset.cotransy ; 


      let bgHeader = visu.dataset.bgHeader


      gsap.to(".App, .wrapper-cases, footer, .video", {backgroundColor:bgHeader, duration:1});

      if(bgHeader === "#E1DFDD" || bgHeader === "#D9B48B"){
      gsap.to(".title, .volvo-BMS, #scroll__down p, #street-configurator", {color:"rgb(0,0,0)", borderColor:"rgb(0,0,0)", duration:1});
      gsap.to(".trackball", {backgroundColor:"rgb(0,0,0)", duration:1});
      gsap.to(".mouse", {borderColor:"rgb(0, 0, 0)", duration:1});
        this.setState({wasBlack: true})
      }else{
        this.setState({wasBlack: false})
        gsap.to(".title, .volvo-BMS, #scroll__down p, #street-configurator", {color:"rgb(255,255,255)", borderColor:"rgb(255,255,255)", duration:1});
        gsap.to(".trackball", {backgroundColor:"rgb(216, 216, 216)", duration:1});
        gsap.to(".mouse", {borderColor:"rgb(216, 216, 216)", duration:1});
      }
      gsap.to('.wall-element',{
        x : translationX,
        y : translationY,
        duration : 2
      })
    }
  }
  elementsWallToCenter(){
    gsap.to('.wall-element',{
      x : 0,
      y: 0,
      duration : 1
    })
  }
  wallToCenter(){
    if(this.state.wasBlack){
      gsap.to(".title, .volvo-BMS, #scroll__down p, #street-configurator", {color:"rgb(255,255,255)", borderColor:"rgb(255,255,255)", duration:1});
      gsap.to(".trackball", {backgroundColor:"rgb(216, 216, 216)", duration:1});
      gsap.to(".mouse", {borderColor:"rgb(216, 216, 216)", duration:1});
    }
    this.elementsWallToCenter();
    if(window.scrollY === 0){
      gsap.to(".App, .wrapper-cases, footer, .video", {backgroundColor:"#000", duration:1});
    }

  }


  reinitWall(){
    this.setState({
      onImage: false,
      timeOut : setTimeout(() => {
        if(!this.state.onImage){
          this.wallToCenter();
          }
      }, 2100)
    })
    
  }

  scrollToCases() {
    window.clearTimeout(this.state.timeOut);
    this.wallToCenter();

    gsap.to(window, {
      scrollTo: {y: 'main', autoKill: false},
      duration: 1,
    });
    gsap.to('nav', {
      opacity : 1,
      duration: .4
    })
    if(!isMobile.isMobile){
      gsap.to(".App, .wrapper-cases, footer, .video", {backgroundColor:'#D7A59C'});
    }

    gsap.to('.wall-element',{
      x : 0,
      y: 0,
      duration : 1
    })
  }

  goToSection(i, scrollTween) {
    this.setState({scrollTween : gsap.to(window, {
      scrollTo: {y: i * window.innerHeight, autoKill: false},
      duration: 1,
      ease: "power2.out",
      onComplete: () => {
        if(i===1){
          this.hideCase()
          this.elementsWallToCenter()
        }
        this.setState({scrollTween:null})
      },
      overwrite: false
    })});
    // console.log(i);
    setTimeout(() => {
      if((i===1 && !isMobile.isMobile) || (i===2 && isMobile.isMobile)){
        this.showCase()
      }
    }, 200);
    ScrollTrigger.refresh()
  }

  showCase(){
      gsap.to('.wrapper-cases', {
        scale: 1, 
        y : 0,
        x : 0,
        // ease: "power4",
        duration: 1,   
      })
      gsap.to(".App, .wrapper-cases, footer, .video", {backgroundColor:'#D7A59C', duration:1});
      gsap.to('nav', {
            opacity : 1,
            duration: .4
      })
      

  }

  hideCase(){
    if(!isMobile.isMobile){
      gsap.to('.wrapper-cases', {
        scale : .4,
        y : "-38vh",
        // delay:1.5
      })
    }else{
      gsap.to('.wrapper-cases', {
        
        scale : .4,
        x:"-13px",
        y : "-38vh",

        // TEMP 
        // scale : 1,
        // x:0
      })
    }
    gsap.to(".App, .wrapper-cases, footer, .video", {backgroundColor:'#000000', duration:1});
    gsap.to('nav', {
      opacity : 0,
      duration: .4
    })
  }

  
//   handleScroll = (e) => {
//     console.log(e);
//     e.preventDefault()
//     // Get the new Value
//     const newValue = window.pageYOffset;
//     console.log(newValue);
// console.log(this.state.oldScrollValue);
//     //Subtract the two and conclude
//     if(this.state.oldScrollValue - newValue < 0){
//         console.log("Up");
//     } else if(this.state.oldScrollValue - newValue > 0){
//         console.log("Down");
//     }

//     // Update the old value
//     this.setState({oldScrollValue: newValue})
//     // oldValue = newValue;
//     return false
//   }
  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
}
  componentDidMount(){
    
    document.querySelector('meta[name="description"]').setAttribute("content", t('metaDescription'));

    

    // var mainIn = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: "main",
    //     toggleActions: "play complete none none",
    //     // start: "top bottom",
    //     onEnter: () => {
    //       this.scrollToCases();
    //     }
    //   }
    // });
    
    let sections = gsap.utils.toArray(".transitionHeader")

    // scrollTween;
    // window.addEventListener('scroll', this.handleScroll);
    
    if(isMobile.isMobile){
    this.hideCase();

    sections.forEach((section, i) => {
      ScrollTrigger.config({
        autoRefreshEvents: "visibilitychange,DOMContentLoaded,load" // notice "resize" isn't in the list
      });
      ScrollTrigger.create({
        trigger: section,
        start: "top bottom",
        end: "+=200%",
        invalidateOnRefresh: true,
        onToggle: self => self.isActive && !this.state.scrollTween && this.goToSection(i ,this.state.scrollTween)
      });
    });
  }


    // gsap.timeline({
    //   scrollTrigger: {
    //     trigger: "header",
    //     toggleActions: "play none none none",
    //     start: "bottom bottom",
    //     // onEnterBack: () => {
    //     //   mainIn.reverse(0);
    //     //   this.elementsWallToCenter();

    //     //   gsap.to(".App, .wrapper-cases", {backgroundColor:'#000000'});

    //     //   gsap.to('nav', {
    //     //     opacity : 0,
    //     //     duration: .4
    //     //   })

    //     //   gsap.to(window, {
    //     //     scrollTo: {y: 0, autoKill: false},
    //     //     duration: 1,
    //     //   });
    //     // }
    //   }
    // });



    gsap.set(".line-title", {
      y:"200px"
    })

    gsap.to(".line-title",{
      y: '0',
      delay: .4,
      stagger:{
        amount: .2
      }
    })

    gsap.set('.animation-visu .one-car', {
      rotation: 0.01,
      height:"0%"
    })
    if(!isMobile.isMobile){
      //DESKTOP
      gsap.to('.animation-visu .one-car', {
        height:"100%",
        stagger:{
          amount: 1
        },
        onComplete: function(){
          // REMOVE BEGIN ANIMATIONS
          gsap.to('.animation-visu .one-car',{
            height:"0"
          })
          // ANIM WRAPPER-CASES ON HEADER
          gsap.to('.wrapper-cases',{
            delay:3,
            height:"100%",
          })

        }
      })
    }else{
      // MOBILE
      gsap.to('.animation-visu .one-car', {
        height:"100%",
        duration : 1,
        stagger:{
          amount: 3
        },
        onComplete: function(){
          // REMOVE BEGIN ANIMATIONS
          gsap.set('.animation-visu.not-last',{
            height:"0"
          })
          // ANIM WRAPPER-CASES ON HEADER
          gsap.to('.wrapper-cases',{
            delay:3,
          })
        }
      })
    }


  }
  render (){ 
    const {isLoaded} = this.state
    return <React.Fragment><header className={"transitionHeader " + (isMobile.isMobile ? "mobile " : "") + i18n.language }>
        
        <div className="wrapper-cars abs wall-element">
          {/* CENTER ANIMATION CARS */}
            <div className="wrapper-visu-animation">
              <VisuAnimation  visuName="visu1"/>
              <VisuAnimation  visuName="visu2" />
              <VisuAnimation  visuName="visu3" />
              <VisuAnimation  visuName="visu4"/>
              <VisuAnimation   visuName="visu5"/>

              {(isMobile.isMobile )? <React.Fragment>
              <VisuAnimation mobile={true} visuName="visu1"/>
              <VisuAnimation mobile={true} visuName="visu2" />
              <VisuAnimation mobile={true} visuName="visu3" />
              <VisuAnimation mobile={true} visuName="visu4"/>
              <VisuAnimation lastMobile={true} mobile={true} visuName="visu5"/>
              </React.Fragment>
              : <div className="wrapper-one-car animation-visu" >
              <div className="wrapper-center">
                <div className="one-car abs bgblack">
                </div>
              </div>
            </div>}
            {/* BLACK BOX TO END ANIM */}
            
          </div>
          
          {/* WALL CARS  */}
          {!isMobile.isMobile ? <div className="wrapper-cars-wall">
            {/* LEFT SIDE  */}
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#D7A59C" carModele="XC40" posX="-13" posY="25" coordTransX="300" coordTransY="50" delay={1.5} format="horizontal" />
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#E1DFDD" carModele="visu1" posX="-25" posY="70" coordTransX="550" coordTransY="-150" delay={1.5} format="vertical" />
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#D9B48B" carModele="visu2" posX="-40" posY="15" coordTransX="700" coordTransY="-50" delay={1.5} format="vertical" />
            {/* <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#A4AE9B" carModele="V60" posX="12" posY="90" coordTransX="250" coordTransY="-200" delay={3.3} format="horizontal" /> */}
            {/* TOP CENTER */}
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#A4AE9B" carModele="visu8" posX="45" posY="-25" coordTransX="-50" coordTransY="250" delay={1.5} format="horizontal" />
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#E1DFDD" carModele="visu4" posX="15" posY="-60" coordTransX="50" coordTransY="450" delay={1.5} format="vertical" />

           

            {/* RIGHT SIDE  */}
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#E1DFDD" carModele="XC60" posX="86"  posY="60" coordTransX="-250" coordTransY="-50" delay={1.6} format="horizontal" />
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#D9B48B" carModele="XC90" posX="93" posY="-2" coordTransX="-450" coordTransY="90" delay={1.7} format="horizontal" />
            
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#D7A59C" carModele="visu5" posX="120" posY="35" coordTransX="-650" coordTransY="60" delay={1.7} format="vertical" />
            <CarsHeader moveWall={(e) => this.moveWall(e)} reinitWall={() => this.reinitWall()} isLoaded={isLoaded} backgroundColor="#A4AE9B" carModele="visu7" posX="130" posY="-10" coordTransX="-800" coordTransY="300" delay={1.7} format="horizontal" />
          </div> : ""}
        </div>
          <div className="copy-wrapper">
          {!isMobile.isMobile ? <p className={"volvo-BMS volvo-BMS-1 tcenter wall-element " + (isLoaded ? "isLoaded" : "loading")} dangerouslySetInnerHTML={{__html: t('BMSMessage1')}}>
          </p> : ""}
          <h1 className="title" >
            <div className="wrapper-title wall-element">
              <div className="wrapper-line-title">
                <span className="line-title">
                  The&nbsp;street&nbsp;is
                </span>
              </div>  
              <div className="wrapper-line-title">
                <span className="line-title">
                  our&nbsp;showroom
                </span>
              </div>
            </div>
            </h1>
            {!isMobile.isMobile ? <p className={"volvo-BMS volvo-BMS-2 tcenter wall-element " + (isLoaded ? "isLoaded" : "loading")} dangerouslySetInnerHTML={{__html: t('BMSMessage2')}}>
          </p> : ""}
          </div>
          {/* STREET CONFIGURATOR DISABLE */}
          <a href={t('linkStreetConfigurator')} target="_blank" rel="noopener" className="abs btn noLu" id="street-configurator" >{t('btnHeader')}</a>

        <div className={"bottom-header abs " + (!isLoaded ? "loaded" : "loading")}>
          <Countdown parentCallback={this.handleCallback} />
          <p className="catch-phrase tcenter">The street is our  <br />  showroom</p> 
          <p className="loading">Loading</p>
        </div>
            

       <div id="scroll__down" onClick={() => this.scrollToCases()} className={isLoaded ? "isLoaded" : "loading"}>
            <div className="mouse">
              <div className="trackball"></div>
            </div>
            <p className="tcenter">
              {t('scrollDown')}
              
            </p>
          </div>

    </header>
    {isMobile.isMobile ?  <section id="preambule" className="transitionHeader">
      <p className="volvo-BMS volvo-BMS-1 tcenter wall-element" dangerouslySetInnerHTML={{__html: t('BMSMessage1')}}></p>
      <p className="volvo-BMS volvo-BMS-2 tcenter wall-element" dangerouslySetInnerHTML={{__html: t('BMSMessage2')}}></p>

    </section> : ""}
   
    </React.Fragment>}
    
};

export default Header;
