import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";





i18n
  .use(detector)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    option : {
      // order and from where user language should be detected
      order: ['querystring'],
    
      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
    
      // cache user language on
      // caches: ['localStorage', 'cookie'],
      // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    
      // optional expire and domain for set cookie
      cookieMinutes: 10,
      // cookieDomain: 'myDomain',
    
      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
    
      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' }
    },
    resources: {
      fr: {
        translation: require('./fr.js').default
      },
      nl: {
        translation: require('./nl.js').default
      },
      lu: {
        translation: require('./lu.js').default
      },
      en: {
        translation: require('./en.js').default
      }
    },
    fallbackLng: "nl",
    debug: false,

    // have a common namespace used around the full app


    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
