import {Component} from 'react'


export class Countdown extends Component{
    constructor(props){
        super(props)
        this.state = {
            pourcentage: null, //TIMER POURCENTAGE
            nPourcentage: 0,
            gap : this.props.pourcentage/this.props.time * 100
        }
    }

    onTrigger = () => {
        this.props.parentCallback();
    }

    

    componentDidMount(){
        window.clearInterval(this.state.timer);
            this.setState({
                nPourcentage: window.setInterval(this.incrementPourcentage.bind(this), 20)
            })
    }

    incrementPourcentage (){
        if(this.state.nPourcentage < 100){

            this.setState(function(state){
                return {
                    nPourcentage : state.nPourcentage + 1
                }
            })
        }else{
            window.clearInterval(this.state.timer)
            window.clearInterval(this.state.pourcentage)
            document.querySelector('.App').classList.add('unlock');
            this.onTrigger()
        }
    }

    componentWillUnmount(){
        window.clearInterval(this.state.pourcentage)
        window.clearInterval(this.state.timer)
    }


    render(){
        return <div id="countdown">
            <div className="bullet">
            </div>  
            <div className="remaining-time">{this.state.nPourcentage}</div> 
             
        </div>
    }
}