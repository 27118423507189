import React, { Component} from 'react'
import logo from '../../img/logo.svg';
import facebook from '../../img/facebook.svg';
import instagram from '../../img/instagram.svg';
import youtube from '../../img/youtube.svg';
import linkedin from '../../img/linkedin.svg';
import { t } from 'i18next';
import i18n from "i18next";




export class Menu extends Component{
  state = {
    isBoxVisible:false,
  };
  componentDidMount(){
    // console.log(i18n);
  }
  setCustomCursor(){
    var customMouse = document.querySelector('#custom-mouse');
    customMouse.classList.add('black');
  }
  clearCustomCursor(){
    var customMouse = document.querySelector('#custom-mouse');
    customMouse.classList.remove('black');
  }
  switchLanguage = () => {
    // console.log(window.location.hostname);
    if(i18n.language === "nl"){
      window.location.href = "/fr"
    } else {
      window.location.href = "/nl"
    }
  }
toggleBox = () => {
  this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
};

  
  render (){ 
    const { isBoxVisible } = this.state;
    return <nav className={`box ${isBoxVisible ? "active" : "visible"}`} onMouseEnter={()=>this.setCustomCursor()}  onMouseLeave={()=>this.clearCustomCursor()}>
            <div className="wrapper-nav">
              <a className="logo-left" href={t('mainURL')} target="_blank" rel="noopener">
                      <img src={logo} className="logo" alt="Volvo" />
                    </a>
              <div id="right-nav">
                <a href={t('ctaAllCaseTopLink')} className="ext-links only-desktop" target="_blank" rel="noopener">{t('ctaAllCaseTop')}</a>
                <a href={t('ourCarsLink')} className="ext-links only-desktop" target="_blank" rel="noopener">{t('ourCars')}</a>
                <span onClick={this.toggleBox} className="button-menu" >Menu</span>
                <div id="menu"  className={`box ${isBoxVisible ? "active" : ""}`}>
                  <div className="wrapper-menu">
                    <div className="logo menu-logo" >
                      <a href={t('mainURL')} target="_blank" rel="noopener">
                        <img src={logo} className="logo" alt="Volvo" />
                      </a>
                      <div onClick={this.toggleBox} className="back-button"></div>
                    </div>
                    <div className="links">
                      <a href={t('ctaAllCaseTopLink')} className="only-mobile" target="_blank" rel="noopener">{t('ctaAllCaseTop')}</a>
                      <a href={t('ourCarsLink')} className="only-mobile" target="_blank" rel="noopener">{t('ourCars')}</a>
                      <a href={t('linkItem1')} target="_blank" rel="noopener">{t('item1')}</a>
                      <a href={t('linkItem2')} target="_blank" rel="noopener">{t('item2')}</a>
                      <a href={t('linkItem3')} target="_blank" rel="noopener">{t('item3')}</a>
                      <a href={t('linkItem4')} target="_blank" rel="noopener">{t('item4')}</a>
                    </div>

                    {i18n.language !== "lu" ? <div className="change-language" onClick={() => this.switchLanguage()} >{t('switchLanguage')}</div> : " "}
                    {/* <div className="change-language" onClick={() => this.props.changeLanguage()} >{t('switchLanguage')}</div> */}
                    <div className="soMe">
                      <a href="https://www.facebook.com/VolvoCarBeLux/" target="_blank" rel="noopener"><img src={facebook} alt="facebook" /></a> 
                      <a href="https://www.instagram.com/volvocarbelux/" target="_blank" rel="noopener"><img src={instagram} alt="instagram" /></a> 
                      <a href="https://www.youtube.com/user/VolvoCarsBelux" target="_blank" rel="noopener"><img src={youtube} alt="youtube" /></a> 
                      <a href="https://www.linkedin.com/company/volvo-car-belux/" target="_blank" rel="noopener"><img src={linkedin} alt="linkedin" /></a> 
                    </div>
                  </div>
                </div>

            </div>
        </div>
    </nav>}
    
};
export default Menu;
