import React, { Component } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { t } from 'i18next';
import arrowSliderNext from '../../img/arrow-next.svg';
import arrowSliderPrev from '../../img/arrow-prev.svg';
// import btnPlayVideo from '../../img/btn-play-video.svg';
// import btnPauseVideo from '../../img/btn-pause-video.svg';
import Vimeo from '@u-wave/react-vimeo';
import isMobile  from './../../constants/global';
import i18n from "i18next";
// const isMobile = true;

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
// gsap.registerPlugin(ScrollToPlugin);
gsap.config({ autoKillThreshold: 1 });

export class Case extends Component {
  constructor(props) {
    if(i18n.language === "lu") {
      var caseVideo = 2;
    }
    else{
      var caseVideo = 3;
    }
    super(props);
    // this.slide = this.slide.bind(this);
    this.state = {
      model: this.props.cases[0].carModele,
      nextModel: this.props.cases[1].carModele,
      copy1: this.props.cases[0].copy1,
      copy2: this.props.cases[0].copy2,
      copy3: this.props.cases[0].copy3,
      copyrightPhoto: this.props.cases[0].copyrightPhoto,
      cta: this.props.cases[0].cta,
      link: this.props.cases[0].link,
      ctaImage: this.props.cases[0].ctaImage,
      ctaImageVideo: this.props.cases[caseVideo].ctaImage,
      activeSlide: 0,
      customCursor: null,
      videoPlaying: false,
      currentAnim: null,
      scrollTween: false,
      currentSlide: 0,
      oldValue: 0,
      newValue: 0,
      scroll: true,

      actionVideo: "Play Video",
      volume: 0,
      paused: true,
      lastScrollTop: 0,

      onHeader: true,
      onFooter: false,
      animationPlaying: false,

    }
    this.setCustomCursor = this.setCustomCursor.bind(this);
    this.clearCustomCursor = this.clearCustomCursor.bind(this)
    this.handleVideo = this.handleVideo.bind(this)
    this.handlePlayerPause = this.handlePlayerPause.bind(this);
    this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
  }

  setCustomCursor(e) {
    var customMouse = document.querySelector('#custom-mouse');
    customMouse.classList.add('bigger');
    // customMouse.firstChild.innerHTML = e.target.dataset.ctaimage

  }

  clearCustomCursor() {
    var customMouse = document.querySelector('#custom-mouse');
    customMouse.classList.remove('bigger');
  }

  updateCase(bgColor) {
    gsap.to(".App, .wrapper-cases, footer, .video", { backgroundColor: bgColor });
  }
  hideInfo() {
    gsap.to(".copyright-photo, .info-case, .cta-conditions", {
      opacity: 0,
      duration: .6
    });
  }

  showInfo() {
    gsap.to(".copyright-photo, .info-case, .cta-conditions", {
      opacity: 1,
      duration: .6
    });
  }



  // goToSection(i, panel) {

  //   console.log(this.state.scrollTween);
  //   console.log(i);
  //   console.log(panel.offsetHeight);

  //   this.state.scrollTween = gsap.to(window, {
  //     scrollTo: {y: i * panel.offsetHeight, autoKill: false},
  //     duration: 1,
  //     onComplete: () => this.state.scrollTween = null,
  //     overwrite: true
  //   });
  // }

  updateInfo(current) {
    let lastSlide = this.props.cases[current + 1] === undefined;
    let currentCase = this.props.cases[current];
    let previousCurrentCase = this.props.cases[current - 1];
    let nextCurrentCase = this.props.cases[current + 1];

   
    if(i18n.language !== "lu"){
      this.setState(state => ({
        model: lastSlide ? previousCurrentCase.carModele : currentCase.carModele,
        nextModel: !lastSlide ? nextCurrentCase.carModele : currentCase.carModele,
        copy1: currentCase.copy1,
        copy2: currentCase.copy2,
        copy3: currentCase.copy3,
        copyrightPhoto: currentCase.copyrightPhoto,
        cta: currentCase.cta,
        ctaImage: currentCase.ctaImage,
        link: currentCase.link,
      }))

      if (lastSlide) {
        this.hideInfo();
        gsap.to(".info-video", {
          opacity: 1,
          duration: .6
        })
  
      } else {
        this.showInfo();
        gsap.to(".info-video", {
          opacity: 0,
          duration: .6
        });
      }
    }else{
      this.setState(state => ({
        model: currentCase.carModele,
        nextModel: !lastSlide ? nextCurrentCase.carModele : currentCase.carModele,
        copy1: currentCase.copy1,
        copy2: currentCase.copy2,
        copy3: currentCase.copy3,
        copyrightPhoto: currentCase.copyrightPhoto,
        cta: currentCase.cta,
        ctaImage: currentCase.ctaImage,
        link: currentCase.link,
      }))
    }
  }

  prevSlide(slide) {
    const tl = gsap.timeline();
    let currentSlide = this.state.currentSlide;
    
    let those = this;
    if (currentSlide >= 1) {

      const panels = gsap.utils.toArray(".wrapper-slider-img");
      const container = panels[currentSlide];

      tl.to(container, 1.5, {
        height: '0%',
        ease: "power2.out",
        onComplete: function () {

          those.setState(state => ({
            animationPlaying: tl.isActive(),
          }))
          // elementPrevCars.classList.add('show')
        }
      });
      // UPDATE SLIDER SPY
      if(!isMobile.isMobile){
        let elementNextCars = document.querySelector(".prev-cars .case" + (currentSlide));
        let elementPrevCars = document.querySelector(".next-cars .case" + (currentSlide));
        elementNextCars.classList.add('hide')
        elementNextCars.classList.remove('show')
  
        elementPrevCars.classList.add('show')
        elementPrevCars.classList.remove('hide')
      }
      
      if (currentSlide + 1 !== panels.length - 1) {
        those.setState({ paused: true })
        // const myVideo  = document.getElementById("video-slider")
        // myVideo.pause();
      }

      this.lineTranslate(currentSlide - 1, panels);

      this.updateCase(this.props.cases[currentSlide - 1].backgroundColor)
      this.updateInfo(currentSlide - 1)
      this.addTag(this.props.cases[currentSlide - 1].carModele);
      this.setState({
        currentSlide: currentSlide - 1,
      });

    }
    if (!isMobile.isMobile) {
      gsap.to(window, {
        scrollTo: { y: window.innerHeight * slide, autoKill: false },
        duration: 1,
      });
      this.setState(state => ({
        animationPlaying: tl.isActive(),
        oldValue: window.innerHeight * (slide)
      }))
    }
  }

  nextSlide(slide) {
    const tl = gsap.timeline();
    const panels = gsap.utils.toArray(".wrapper-slider-img");
    let currentSlide = this.state.currentSlide;
    let those = this;
    if ((currentSlide + 1) <= (panels.length - 1)) {
      // UPDATE SLIDER SPY
      if(!isMobile.isMobile){
        let elementNextCars = document.querySelector(".next-cars .case" + (currentSlide + 1));
        let elementPrevCars = document.querySelector(".prev-cars .case" + (currentSlide + 1));
        elementNextCars.classList.add('hide')
        elementPrevCars.classList.add('show');
        elementNextCars.classList.remove('show')
        elementPrevCars.classList.remove('hide')
      }
      const container = panels[currentSlide + 1];
      tl.to(container, 1.5, {
        height: "100%",
        ease: "power2.out",
        onComplete: function () {
          those.setState(state => ({
            animationPlaying: tl.isActive(),
          }))
        }
      });
     
      this.lineTranslate(currentSlide + 1, panels);

      this.updateCase(this.props.cases[currentSlide + 1].backgroundColor);
      this.updateInfo(currentSlide + 1)
      this.addTag(this.props.cases[currentSlide + 1].carModele);
      this.setState({
        currentSlide: currentSlide + 1,
      });
    }
    else {
      // Scroll To footer
      if (isMobile.isMobile) {
        // setTimeout(() => {
        // let d = document.documentElement;
        // let height = d.offsetHeight;
        // console.log(height);
        gsap.to(window, {
          duration: 15,
          scrollTo: {
            y: document.body.scrollHeight,
            autoKill: true,

          },
          ease: "none",
          onComplete: () => {
            window.scroll({ left: 0, top: document.body.scrollHeight });
          }
        });
        // window.scroll({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        // }, 200);

      }
    }
    //FOR DESKTOP SCROLL ANIMATION
    if (!isMobile.isMobile) {
      gsap.to(window, {
        scrollTo: { y: window.innerHeight * (slide + 2), autoKill: false },
        duration: 1,
      });
      this.setState(state => ({
        animationPlaying: tl.isActive(),
        oldValue: window.innerHeight * (slide + 2)
      }))
    }
  }

  lineTranslate(currentSlide, panels) {
    let trackerSlider = document.querySelector('.tracker');
    let lineSlider = document.querySelector('.solid-line');

    gsap.to(trackerSlider, {
      bottom: "100" - (((currentSlide) / (panels.length - 1)) * 100) + "%",
      duration: .5,
      ease: "power2.out"
    });

    gsap.to(lineSlider, {
      height: "100" - (((currentSlide) / (panels.length - 1)) * 100) + "%",
      duration: .5,
      ease: "power2.out"
    });
  }
  showCase() {
    console.log("show");
    gsap.to('.wrapper-section', {
      scale: 1,
      top: "0vh",
      // x : 0,
      // ease: "power4",
      duration: 1,
      onComplete: () => {
        this.addTag(this.props.cases[0].carModele)

        this.setState({ oldValue: window.innerHeight, onHeader: false, animationPlaying : false });
      }
    })
    gsap.to('#cta-configurator', {
      opacity: 1,
      duration: 0.2,
      delay: 1
    })

    gsap.to('header', {
      top: "-100vh",
      duration: 1,
    })
    gsap.to(".App, .wrapper-cases, footer, .video", { backgroundColor: '#D7A59C', duration: 1 });
    gsap.to('nav', {
      opacity: 1,
      duration: .4
    })
    this.setState({animationPlaying : true});
    document.querySelector('nav').classList.add('visible');

  }

  hideCase() {
    if (!isMobile.isMobile) {
      gsap.to('header', {
        top: "0vh",
        duration: 1,
      })
      gsap.to('.wrapper-section', {
        scale: .4,
        top: "60vh",
        // delay:1.5
        onComplete: () => {
          this.setState({ oldValue: 0, animationPlaying : false });
        }
      })
      gsap.to('#cta-configurator', {
        opacity: 0,
        duration: 0.2
      })
    } else {
      gsap.to('.wrapper-cases', {
        scale: .4,
        top: "100vh",
        y: "-38vh",

        // TEMP 
        // scale : 1,
        // x:0
      })
      gsap.to('#cta-configurator', {
        opacity: 0,
        duration: 0.2
      })
    }
    this.setState({ onHeader: true,animationPlaying : true });
    gsap.to(".App, .wrapper-cases, footer, .video", { backgroundColor: '#000000', duration: 1 });
    gsap.to('nav', {
      opacity: 0,
      duration: .4
    })
    document.querySelector('nav').classList.remove('visible');
  }



  disable() {
    function preventScroll(e) {
      e.preventDefault();
      e.stopPropagation();
      // console.log('scroll kill');
      return false;
    }
    document.querySelector('.App').addEventListener('wheel', preventScroll);
      setTimeout(function(){
        document.querySelector('.App').removeEventListener('wheel', preventScroll);
      }, 1000);    
    
  }

  checkscroll() {     // ------------------
    // ------------------
    this.disable();
    var scrollValue = window.pageYOffset;
    if (this.state.oldValue < scrollValue) {             // downscroll code
      // // FRONT WAY
      if ((this.state.onHeader && this.state.animationPlaying === false)) {
        this.setState({ onHeader: false });
        // this.nextSlide();
        gsap.to(window, {
          scrollTo: { y: window.innerHeight * 1, autoKill: false },
          duration: 1,
          onComplete: () => {
            this.setState({ onHeader: false, oldValue: window.innerHeight * 1 })
          }
        });
        // console.log('SHOW case from header');
        this.showCase();
        // setTimeout(enable(), 100);
        return
      }
      if ((this.state.animationPlaying === false) && (this.state.currentSlide === 0) && (this.state.oldValue === (window.innerHeight * 1))) {
        // console.log('SHOW CASE 2 from 1');
        this.nextSlide(this.state.currentSlide);
        // setTimeout(enable(), 100);
        return
      }
      if ((this.state.animationPlaying === false) && (this.state.currentSlide === 1) && (this.state.oldValue === (window.innerHeight * 2))) {
        // console.log('SHOW CASE 3 from 2');
        this.nextSlide(this.state.currentSlide);
        // setTimeout(enable(), 100);
        return
      }
      if(i18n.language !== "lu"){
        if ((this.state.animationPlaying === false) && (this.state.currentSlide === 2) && (this.state.oldValue === (window.innerHeight * 3))) {
          // console.log('SHOW CASE 4 from 3');
          this.nextSlide(this.state.currentSlide);
          // setTimeout(enable(), 100);
          return
        }
        if ((this.state.animationPlaying === false) && (this.state.currentSlide === 3) && (this.state.oldValue === (window.innerHeight * 4))) {
          // console.log('SHOW CASE 5 from 4');
          this.removeScrollonIframes();
          this.nextSlide(this.state.currentSlide);
          // setTimeout(enable(), 100);
          return
        }
        if ((this.state.animationPlaying === false) && (!this.state.onFooter) && (this.state.oldValue === (window.innerHeight * 5))) {
  
          gsap.to(window, {
            scrollTo: { y: window.innerHeight * 6, autoKill: false },
            duration: 1,
          });
          gsap.to('.wrapper-section', {
            top: "-50vh",
            autoKill: false,
            duration: 1,
            onComplete: () => {
              this.setState({ onFooter: true, oldValue: window.innerHeight * 6, animationPlaying : false })
            }
          });
          this.setState({animationPlaying : true})
          // setTimeout(enable(), 100);
          return
  
        }
      }
      else {
        if ((this.state.animationPlaying === false) && (!this.state.onFooter) && (this.state.oldValue === (window.innerHeight * 3))) {
  
          gsap.to(window, {
            scrollTo: { y: window.innerHeight * 6, autoKill: false },
            duration: 1,
          });
          gsap.to('.wrapper-section', {
            top: "-50vh",
            autoKill: false,
            duration: 1,
            onComplete: () => {
              this.setState({ onFooter: true, oldValue: window.innerHeight * 6, animationPlaying : false })
            }
          });
          this.setState({animationPlaying : true})
          // setTimeout(enable(), 100);
          return
  
        }

      }
    } else if (this.state.oldValue > scrollValue) {
      
      if ((this.state.oldValue <= window.innerHeight * 1) && (this.state.onHeader === false)) {
        gsap.to(window, {
          scrollTo: { y: 0, autoKill: false },
          duration: 1,
          onComplete: () => {
            this.setState({ onHeader: true })
          }
        });
        // console.log('SHOW Header from case');
        this.hideCase();
        // setTimeout(enable(), 100);
        return
      }
      if ((this.state.animationPlaying === false) && (this.state.currentSlide === 1) && (this.state.oldValue === (window.innerHeight * 2))) {
        // console.log('SHOW CASE 1 from 2');
        this.prevSlide(this.state.currentSlide);
        // setTimeout(enable(), 100);
        return
      }
      if ((this.state.animationPlaying === false) && (this.state.currentSlide === 2) && (this.state.oldValue === (window.innerHeight * 3))) {
        // console.log('SHOW CASE 2 from 3');
        this.prevSlide(this.state.currentSlide);
        // setTimeout(enable(), 100);
        return
      }
      if(i18n.language !== "lu"){
        if ((this.state.animationPlaying === false) && (this.state.currentSlide === 3) && (this.state.oldValue === (window.innerHeight * 4))) {
          // console.log('SHOW CASE 3 from 4');
          this.prevSlide(this.state.currentSlide);
          // setTimeout(enable(), 100);
          return
        }
        if ((this.state.animationPlaying === false) && (this.state.currentSlide === 4) && (this.state.oldValue === (window.innerHeight * 5))) {
          // console.log('SHOW CASE 4 from 5');
          this.prevSlide(this.state.currentSlide);
          // setTimeout(enable(), 100);
          return
        }
        if ((this.state.onFooter)) {
          // this.nextSlide();
          gsap.to(window, {
            scrollTo: { y: window.innerHeight * 5, autoKill: false },
            duration: 1,
          });
          gsap.to('.wrapper-section', {
            top: "0vh",
            autoKill: false,
            duration: 1,
            onComplete: () => {
              this.setState({ onFooter: false, oldValue: window.innerHeight * 5, animationPlaying : false })
            }
          });
          this.setState({animationPlaying : true})
          // console.log('SHOW Cases from footer');
          // setTimeout(enable(), 100);
          return
  
        }
      } else {

        if ((this.state.onFooter)) {
          // this.nextSlide();
          gsap.to(window, {
            scrollTo: { y: window.innerHeight * 3, autoKill: false },
            duration: 1,
          });
          gsap.to('.wrapper-section', {
            top: "0vh",
            autoKill: false,
            duration: 1,
            onComplete: () => {
              this.setState({ onFooter: false, oldValue: window.innerHeight * 3, animationPlaying : false })
            }
          });
          this.setState({animationPlaying : true})
          // console.log('SHOW Cases from footer');
          // setTimeout(enable(), 100);
          return
  
        }
      }
    }
  }

  componentDidMount() {
    this.updateInfo(0);
    if (!isMobile.isMobile) {
      gsap.set('.wrapper-section', {
        scale: .4,
        top: "60vh",
      });





      function debounce_leading(func, timeout = 100) {
        let timer;
        return (...args) => {
          if (!timer) {
            func.apply(this, args);
          } else {
          }
          clearTimeout(timer);
          timer = setTimeout(() => {
          timer = undefined;
          }, timeout);
        };
      }      
      function debounce_scroll(func, timeout = 1) {
        let timer;
        return (...args) => {
          if (!timer) {
            func.apply(this, args);
          } else {
          }
          clearTimeout(timer);
          timer = setTimeout(() => {
          timer = undefined;
          }, timeout);
        };
      }
      document.addEventListener('scroll', debounce_leading(() => this.checkscroll()));
      document.addEventListener('scroll', debounce_scroll(() => this.disable()));
    }

  }

  toggleFullScreen = (el) => {
    if (el.requestFullscreen) {
      el.requestFullscreen();
      el.muted = false;
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
      el.muted = false;
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
      el.muted = false;
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
      el.muted = false;
    } else {
      this.pauseVideo();
      el.muted = true;
    }
  }


  handleVideo() {
    let btnsControls = document.querySelectorAll('.btn-video img');
    for (let i = 0; i < btnsControls.length; i++) {
      btnsControls[i].classList.toggle('active');
    }
    if (this.state.paused) {
      // console.log('PLAY');
      this.handlePlayerPlay();
    } else {
      // console.log('PAUSE');
      this.handlePlayerPause();
    }
    // const myVideo  = document.getElementById("video-slider")
    // this.toggleFullScreen(myVideo);
  }


  pauseVideo = () => {
    const myVideo = document.querySelector('.video-to-play')
    const customMouse = document.querySelector("#custom-mouse");
    myVideo.pause()
    customMouse.classList.remove('hide');
    myVideo.classList.add('pause');
    customMouse.firstChild.innerHTML = t('videoCtaImage')
    this.setState(state => ({
      videoPlaying: false,
    }))

  }
  playVideo = () => {
    const myVideo = document.getElementById("video-slider");
    const customMouse = document.querySelector("#custom-mouse");
    myVideo.play()
    myVideo.muted = true;
    customMouse.classList.add('hide');
    myVideo.classList.remove('pause');
    customMouse.firstChild.innerHTML = t('videoCtaImage')
    this.setState(state => ({
      videoPlaying: true,
    }))
  }

  removeScrollonIframes(){
    var iframe = document.querySelector("iframe");
    iframe.setAttribute('scrolling', 'no');
    // console.log("remove this scroll");
  }
  handlePlayerPause() {
    this.setState({ paused: true, actionVideo: "Play Video", volume: 0 });
  }

  handlePlayerPlay() {
    this.setState({ paused: false, actionVideo: "Pause Video", volume: 1 });
  }
  addTag(car) {
    // console.log(car);
    window.dataLayer.push({
      event: 'VirtualPageview',
      virtualPageURL: `/${car}`,
      virtualPageTitle: car
    });
  }

  render() {
    if (i18n.language === "lu") {
      var country = "lu";
    } else { 
      var country = "be";
    }
    const myCases = this.props.cases
    const listCarsPrev = myCases.map((oneCase, index) => {
      return (
        <div key={"prev" + index} className={oneCase.carModele + " case" + index}>{oneCase.carModele}</div>
      )
    })
    const listCarsNext = myCases.map((oneCase, index) => {
      return (
        <div key={"next" + index} className={oneCase.carModele + " case" + index}>{oneCase.carModele}</div>
      )
    })
    const { paused, volume } = this.state;
    const listCases = myCases.map((oneCase, index) => {
      return (
        (!oneCase.isVideo) ? <div /*onMouseEnter={(e)=>this.setCustomCursor(e, false)}*/ key={"case" + index} id={oneCase.carModele} className={index + " wrapper-slider-img"} ><a onClick={() => this.addTag(oneCase.carModele)} href={oneCase.linkCta} target="_blank" rel="noopener"><img data-ctaimage={oneCase.ctaImage} src={require("../../img/case/" + country + "/case-" + oneCase.carModele + ".jpg").default} alt={oneCase.carModele} /></a></div> :
          <div key={"case" + index} id={oneCase.carModele} className="wrapper-slider-img video"  >
            <Vimeo video={t('videoURL')} responsive volume={volume} paused={paused} onPause={this.handlePlayerPause} onPlay={this.handlePlayerPlay} />

            {/* <iframe  src="https://player.vimeo.com/video/654445872?h=0209810e13" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
            {/* <video  autoPlay loop muted playsInline id="video-slider" data-ctaimage={oneCase.ctaImage} className="video-to-play pause"  onClick={()=>this.handleVideo()} onMouseEnter={()=>this.playVideo()} onMouseLeave={()=>this.pauseVideo()}>
                    <source  src={require("../../video/video-" + i18n.language + ".mp4").default} type="video/mp4"  poster={require("../../img/case/VIDEO.jpg").default} ></source>
                  </video> */}

          </div>

      )
    })


    return (
      <div className={"wrapper-section " + (isMobile.isMobile ? "mobile" : "")} >


        <div className="content-wrapper">
          <div className="big-wrapper">
            <div className="copyright-photo abs">{this.state.copyrightPhoto}</div>

            <div  /*onMouseLeave={()=>this.clearCustomCursor()} */ className="wrapper-cases">
              <div className="all-cases">
                {listCases}
              </div>
            </div>

            {isMobile.isMobile ?<div className="wrapper-arrows">
              <div className="prev" onClick={() => this.prevSlide()}><img src={arrowSliderPrev} alt="" /></div>
              <div className="next" onClick={() => this.nextSlide()}><img src={arrowSliderNext} alt="" /></div>
            </div> :  " "}
          </div> 

          <div className="wrapper-info">
            <div className="info-case">
              <div className="model" >{this.state.model} <br /> {this.state.copy1}</div>
              <div className="description" dangerouslySetInnerHTML={{ __html: this.state.copy2 }} ></div>
              <div className="price" dangerouslySetInnerHTML={{ __html: this.state.copy3 }}></div>
              <a onClick={() => this.addTag(this.state.model)} href={this.state.link} target="_blank" rel="noopener" className="cta-conditions btn" dangerouslySetInnerHTML={{ __html: this.state.cta }}></a>
            </div>
            <div className="info-video">
              <div className="cta-play-video">
                {t('ctaVideo')}
              </div>
              {/* <div className="btn btn-video" onClick={()=>this.handleVideo()}>
                        <div className="icon-play-video">
                        <img className="btnPlayVideo active" src={btnPlayVideo} alt="" />
                        <img className="btnPauseVideo" src={btnPauseVideo} alt="" />
                        </div>
                        {this.state.actionVideo}
                      </div> */}

            </div>

          </div>


        </div>

        {!isMobile.isMobile ?<div id="slider">
          {/* <span className="current-case" onClick={()=>this.prevSlide()}> */}
          <span className="current-case list-cars prev-cars">
            {/* {this.state.model} */}
            {listCarsPrev}
          </span>
          <div className="line-slider">
            <div className="solid-line"></div>
            <div className="tracker"></div>
          </div>
          {/* <div className="slider-cases" onClick={()=>this.nextSlide()}>  alternative*/}
          <div className="slider-cases list-cars next-cars">
            {/* {this.state.nextModel} alternative */}
            {listCarsNext}
          </div>
        </div> : ""}
        {/* STREET CONFIGURATOR DISABLE */}
        <a href={t('linkStreetConfigurator')} target="_blank" rel="noopener" id="cta-configurator" className="abs noLu">
          <p dangerouslySetInnerHTML={{__html: t('footerMain')}}></p>  
        </a>
      </div>
    );
  }
}

export default Case;
