import React, { useRef, useEffect } from 'react'

import { gsap } from "gsap";
import i18n from "i18next";




const  CarsHeader = (props) => {
    
  // const [onimage, setOnimage] = useState(false);


    const carRef=  useRef(null);
    const carRefWrapper=  useRef(null);
    


    useEffect(() => {
        gsap.set(carRefWrapper.current, 
          {
            opacity:1,
            transformOrigin : "100% 100%",
            rotation: 0.01,
            x: props.posX + "vw",
            y: props.posY + "vh",
          })
        // if(!props.isLoaded && !props.isAnimation){
          gsap.to(carRef.current,{
            delay : props.delay,
            ease : "power2",
            height: "100%",
              duration : .8,
            });
        // }  
      });


    if (i18n.language === "lu") {
      var country = "lu";
    } else { 
      var country = "be";
    }


    return (
    <div id={"car-"+ props.carModele} ref={carRefWrapper} className={"wrapper-one-car car-wall " + props.format} >
        <div ref={carRef} data-bg-header={props.backgroundColor} data-cotransx={props.coordTransX} data-cotransy={props.coordTransY} className="one-car abs" onMouseEnter={(e) => props.moveWall(e)} onMouseLeave={() => props.reinitWall()}  >
          <img className="carsHeader" src={require("../../img/carsheader/" + country + "/header-"+ props.carModele + ".jpg").default} alt={props.carModele} />
        </div>
    </div>)
    
};

export default CarsHeader;
