import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import Menu from "./components/main/Menu";
import { changeLanguage } from 'i18next';

import './App.scss';


function App() {
  // var elementToChange = document.getElementsByTagName("body")[0];
  // elementToChange.style.cursor = "url('cursor url with protocol'), zoom-in";
  // changeLanguage("nl")
  // function switchLanguage(){
  //   this.setState({
  //     currentLanguage : (this.state.currentLanguage=="fr") ? "nl" : "fr"
  //   })
  //   this.state.currentLanguage == "fr" ? changeLanguage("nl") : changeLanguage("fr")
  // }
  // console.log(i18n);
  let langURL = window.location.href;
  if(langURL.indexOf('.lu') > -1){
    changeLanguage("lu");
  }else if(langURL.indexOf('/lu') > -1){
    changeLanguage("lu");
  }else if(langURL.indexOf('/fr') > -1){
    changeLanguage("fr");
  }else if(langURL.indexOf('/en') > -1){
    changeLanguage("en");
  }else {
    changeLanguage("nl");
  }
  function handleCursor(e){
    var el = document.getElementById('custom-mouse');
    // var e = window.event;
    // el.innerHTML = '<div>'+"copy"+'</div>'
        el.style.top = e.clientY + "px";
        el.style.left = e.clientX + "px";
  }
  return (
    <div className="App" onMouseMove={(e) => handleCursor(e)}>
      <div id="custom-mouse"><div className="content-custom-mouse">PLAY&nbsp;FULL<br />VIDEO</div></div>
      <Header/>
      <Main/>
      <Menu />
      <Footer/>
    </div>
  );
}

export default App;
