// SAME AS FR EXCEPT PRICES
const translationLU = {
  metaDescription : "Pas de Salon de l’Auto. Mais partout dans les rues.",

  // HEADER 
  mainURL: "https://www.volvocars.com/lu",
  BMSMessage1 : "Si vous cherchez une nouvelle Volvo,<br/>regardez autour de vous. <br/><br/>Votre future Volvo n’est surement pas très loin.",
  BMSMessage2 : "Nous avons envoyé des street photographers dans nos rues pour capturer tous les modèles Volvo.<br />Découvrez-les&nbsp;ici.",
  meetPhotographers : "Rencontrez nos street photographers",
  scrollDown : "Scrollez pour découvrir nos modèles et nos offres temporaires.",
  btnHeader : "Découvrez notre Street Configurator",
  linkXC40 : "https://www.volvocars.com/lu/v/cars/xc40",
  linkXC60 : "https://www.volvocars.com/lu/v/cars/xc60",
  linkXC90 : "https://www.volvocars.com/lu/v/cars/xc90",
  linkV60 : "https://www.volvocars.com/lu/v/cars/v40",

  // MENU
  ourCars: "Nos voitures",
  ourCarsLink: "https://www.volvocars.com/lu/modeles",
  item1 : "Configurateur",
  linkItem1 : "https://www.volvocars.com/lu/build",
  item2 : "Conduire une Volvo électrique",
  linkItem2 : "https://www.volvocars.com/lu/v/cars/electric-cars",
  item3 : "Trouver un distributeur",
  linkItem3 : "https://www.volvocars.com/lu/dealers/distributeurs",
  item4 : "Contact",
  linkItem4 : "https://www.volvocars.com/lu/contact",
  switchLanguage: "Belgium (Nederlands)", 

  // MAIN
  footerMain: "Vous avez repéré une Volvo&nbsp;? Testez notre Street Configurator",
  

  //CASES
  ctaAllCaseTop : "Demandez votre offre",
  ctaAllCaseTopLink : "https://www.volvocars.com/lu/quote?campaigncode=Brus-motorshow-2022-B_VCBE",
  //CASE1 - XC40
  case1Photographer : "© Wouter Van Vaerenbergh, Rue Albert 1er, Luxembourg",
  case1Copy1 : "Recharge T5 Plug-in hybrid",
  case1Copy2 : "Ice White<br/>19” wheels",
  case1Copy3 : "Modèle de base àpd <span>32.008 €</span>",
  case1CtaImage : "Découvrez la&nbsp;XC40",
  case1LinkCtaImage : "https://www.volvocars.com/lu/v/cars/xc40",
  case1Cta : "Découvrez nos<br/>offres",
  case1Link : "https://www.volvocars.com/lu/acheter/votre-nouvelle-volvo/offres/xc40 ",
  //CASE2 - XC60
  case2Photographer : "© Wouter Van Vaerenbergh, Beim Beinchen, Luxembourg",
  case2Copy1 : "Mild hybrid",
  case2Copy2 : "Thunder Grey<br/>19” wheels",
  case2Copy3 : "Modèle de base àpd <span>49.800 €</span>",
  case2CtaImage : "Découvrez la&nbsp;XC60",
  case2LinkCtaImage : "https://www.volvocars.com/lu/v/cars/xc60",
  case2Cta : "Découvrez nos<br/>offres",
  case2Link : "https://www.volvocars.com/lu/acheter/votre-nouvelle-volvo/offres/xc60",
  //CASE4 - V60
  case4Photographer : "© Wouter Van Vaerenbergh, Rue de la Semois, Luxembourg",
  case4Copy1 : "Mild hybrid",
  case4Copy2 : "Denim Blue<br/>18” wheels",
  case4Copy3 : "Modèle de base àpd <span>39.502 €</span>",
  case4CtaImage : "Découvrez la&nbsp;V60",
  case4LinkCtaImage : "https://www.volvocars.com/lu/v/cars/v60",
  case4Cta : "Découvrez nos<br/>offres",
  case4Link : "https://www.volvocars.com/lu/acheter/votre-nouvelle-volvo/offres/v60",
  //VIDEO
  videoCtaImage : "Play&nbsp;full <br /> Video",
  videoCtaImageStop : "Pause <br /> Video",
  ctaVideo : "Découvrez nos street photographers",
  seeMore : "Découvrez-plus",
  videoURL : "https://player.vimeo.com/video/654445872?h=0209810e13",

  //FOOTER 
  footer1: "Découvrez toutes nos offres",
  footer1Link: "https://www.volvocars.com/lu/acheter/votre-nouvelle-volvo/offres ",
  footer2: "Trouvez votre distributeur",
  footer2Link: "https://www.volvocars.com/lu/dealers/distributeurs",
  footer3: "Testez notre Street Configurator",

  linkStreetConfigurator: "https://www.streetconfigurator.be/fr",

  }
  
  export default translationLU;