const translationNL = {
  metaDescription : "Niet op hetAutosalon. Wel in elkestraat.",
  // HEADER 
  mainURL: "https://www.volvocars.com/",
  BMSMessage1 : "Dit jaar is er geen&nbsp;Autosalon.<br /> Maar gelukkig ziet u Volvo overal rondom&nbsp;u. <br />Elegant in beweging. Of gewoon, rustig&nbsp;geparkeerd.<br /> <br /> U merkt ongetwijfeld een Volvo op die u&nbsp;bevalt.",
  BMSMessage2 : "We stuurden 3 street photographers op pad, om al onze modellen vast te&nbsp;leggen. <br/ > Ontdek ze&nbsp;hieronder.",
  


  meetPhotographers : "Ontmoet onze street fotografen",
  scrollDown : "Scroll verder en ontdek onze modellen en aanbiedingen",
  btnHeader : "Ontdek de Street Configurator",


  // MENU
  ourCars: "Onze auto's",
  ourCarsLink: "https://www.volvocars.com/nl-be/modellen",
  item1 : "Configurator",
  linkItem1 : "https://www.volvocars.com/nl-be/build",
  item2 : "Elektrisch rijden",
  linkItem2 : "https://www.volvocars.com/nl-be/v/cars/electric-cars",
  item3 : "Verdelers",
  linkItem3 : "https://www.volvocars.com/nl-be/dealers/verdelers",
  item4 : "Contact",
  linkItem4 : "https://www.volvocars.com/nl-be/contact",
  switchLanguage: "Belgium (Français)", 

  // MAIN
  footerMain: "Een Volvo gespot? Test onze Street Configurator.",
  

  //CASES
  ctaAllCaseTop : "Vraag uw offerte aan",
  ctaAllCaseTopLink : "https://www.volvocars.com/nl-be/quote?campaigncode=Brus-motorshow-2022-B_VCBE",
  //CASE1 - XC40
  case1Photographer : "© Thomas Switn Sweertvaegher, Ottergemsesteenweg – 9000 Gent",
  case1Copy1 : "Plug-in Hybrid R-Design",
  case1Copy2 : "Ice White <br />18” wheels",
  case1Copy3 : "Basismodel vanaf <span>€32.800</span>",
  case1CtaImage : "Ontdek de&nbsp;XC40",
  case1LinkCtaImage : "https://www.volvocars.com/nl-be/v/cars/xc40",
  case1Cta : "Ontdek onze <br /> aanbiedingen",
  case1Link : "https://www.volvocars.com/nl-be/l/aanbiedingen/b2c/xc40",
  //CASE2 - XC60
  case2Photographer : "© Wouter Van Vaerenbergh, Kwatrechtstraat – 9230 Wetteren",
  case2Copy1 : "Plug-in Hybrid R-Design",
  case2Copy2 : "Silver Dawn <br />19” wheels",
  case2Copy3 : "Basismodel vanaf <span>€51.200</span>",
  case2CtaImage : "Ontdek de&nbsp;XC60",
  case2LinkCtaImage : "https://www.volvocars.com/nl-be/v/cars/xc60",
  case2Cta : "Ontdek onze <br /> aanbiedingen",
  case2Link : "https://www.volvocars.com/nl-be/l/aanbiedingen/b2c/xc60",
  //CASE3 - XC90
  case3Photographer : "© Thomas Switn Sweertvaegher, Nieuwevaart - 9000 Gent",
  case3Copy1 : "Mild Hybrid Inscription",
  case3Copy2 : "Birch Light <br />21” wheels",
  case3Copy3 : "Basismodel vanaf <span>€73.840</span>",
  case3CtaImage : "Ontdek de&nbsp;XC90",
  case3LinkCtaImage : "https://www.volvocars.com/nl-be/v/cars/xc90",
  case3Cta : "Ontdek onze <br /> aanbiedingen",
  case3Link : "https://www.volvocars.com/nl-be/l/aanbiedingen/b2c/xc90",
  //CASE4 - V60
  case4Photographer : "© Thomas Switn Sweertvaegher, Rodebergstraat - 8950 Heuvelland",
  case4Copy1 : "Mild Hybrid Momentum Pro",
  case4Copy2 : "Pebble Grey <br />19” wheels",
  case4Copy3 : "Basismodel vanaf <span>€40.550</span>",
  case4CtaImage : "Ontdek de&nbsp;V60",
  case4LinkCtaImage : "https://www.volvocars.com/nl-be/v/cars/v60",
  case4Cta : "Ontdek onze <br /> aanbiedingen",
  case4Link : "https://www.volvocars.com/nl-be/l/aanbiedingen/b2c/v60",
  //VIDEO

  videoCtaImage : "Play&nbsp;full <br /> Video",
  videoCtaImageStop : "Pause <br />Video",
  ctaVideo : "Ontmoet onze street photographers",
  seeMore : "Ontdeek meer",
  videoURL : "https://player.vimeo.com/video/654445926?h=fafa6f80e6",
  //FOOTER 
  footer1: "Ontdek al onze aanbiedingen",
  footer1Link: "https://www.volvocars.com/nl-be/l/aanbiedingen/b2c/",
  footer2: "Vind uw verdeler",
  footer2Link: "https://www.volvocars.com/nl-be/dealers/verdelers",
  footer3: "Test onze Street Configurator",


  linkStreetConfigurator: "https://volvostreetconfigurator.be/nl",
  
}

export default translationNL;