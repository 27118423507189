// import React, { Component, useRef, useEffect } from 'react'

// import { gsap } from "gsap";

import i18n from "i18next";




if (i18n.language === "lu") {
  var country = "lu";
} else { 
  var country = "be";
}

const  VisuAnimation = (props) => {
    
 
    return (
    <div className={"wrapper-one-car animation-visu " + (props.lastMobile ? "last" : "not-last")} >
      <div className="wrapper-center">
        <div  className={"one-car abs "  + (props.mobile ? "mobile" : "not-mobile")}>
          <img className="carsHeader" src={require("../../img/carsheader/" + country + "/header-"+ props.visuName + ".jpg").default} alt={props.visuName} />
        </div>
      </div>
    </div>)
    
};

export default VisuAnimation;
