import React, { useRef, useEffect } from 'react'
import { t } from 'i18next';
import i18n from "i18next";
import { gsap } from "gsap";

const  Footer = (props) => {
    const links=  useRef(null);


    

    useEffect(() => {
        window.onscroll = function() {
            let d = document.documentElement;
            let offset = d.scrollTop + window.innerHeight;
            let height = d.offsetHeight;
            if (offset >= height-40) {
                launchFooterAnim()
            }
        };
        gsap.set('.wrapper-link a', 
            {
                y: 100
            })
        gsap.set('.footer-line', 
        {
            width: "0%",
            transformOrigin: "0 50% 0"
        })
        gsap.set('.footer-arrow', 
        {
            opacity: 0
        })
    });

    function launchFooterAnim(){
        gsap.to('.wrapper-link a', 
        {
            y: 0,
            stagger:{
                amount: -.6
            },
        })
        gsap.to('.footer-line', 
        {
            delay: .2,
            width: "98%",
            stagger:{
                amount: -.5
            }
        })
        gsap.to('.footer-arrow', 
        {
            scrollTrigger: "footer",
            delay: .6,
            opacity: 1,
            stagger:{
                amount: -.4
            }
        })
    }
    if(i18n.language !== "lu"){
        return <footer>
            <div ref={links} className="links">
                <div className="wrapper-link"> 
                    <div className="footer-line"></div>
                    <a href={t('footer1Link')} target="_blank" rel="noopener">{t('footer1')}</a>
                    <div className="footer-arrow"></div>

                </div>
                <div className="wrapper-link"> 
                    <div className="footer-line"></div>
                    <a href={t('footer2Link')} target="_blank" rel="noopener">{t('footer2')}</a>
                    <div className="footer-arrow"></div>


                </div>
            {/* STREET CONFIGURATOR DISABLE */}
                <div className="wrapper-link"> 
                    <div className="footer-line"></div>
                    <a href={t('linkStreetConfigurator')} target="_blank" rel="noopener">{t('footer3')}</a>
                    <div className="footer-arrow"></div>

                </div>
                
            </div> 
        </footer>
    } else {
        return <footer>
            <div ref={links} className="links">
                <div className="wrapper-link"> 
                    <div className="footer-line"></div>
                    <a href={t('footer1Link')} target="_blank" rel="noopener">{t('footer1')}</a>
                    <div className="footer-arrow"></div>

                </div>
                <div className="wrapper-link"> 
                    <div className="footer-line"></div>
                    <a href={t('footer2Link')} target="_blank" rel="noopener">{t('footer2')}</a>
                    <div className="footer-arrow"></div>


                </div>
            {/* STREET CONFIGURATOR DISABLE */}
                {/* <div className="wrapper-link"> 
                    <div className="footer-line"></div>
                    <a href={t('linkStreetConfigurator')} target="_blank" rel="noopener">{t('footer3')}</a>
                    <div className="footer-arrow"></div>

                </div> */}
                
            </div>  
        </footer>

    }
}

export default Footer;
